import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../assets/scss/main.scss"

class PrivacyPolicy extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Marco Mazzocchi - Privacy Policy" />
        <div className="container my-5">
          <div className="row justify-content-md-center">
            <div className="col-md-8">
              <h1>Privacy Policy</h1>
              <p>Questo sito utilizza Google Analytics per raccogliere statistiche anonime sull'utilizzo del sito stesso.</p>
              <p>Quando chiudi il banner sulla Privacy Policy viene memorizzato l'evento nel Local Storage del browser per evitare che si apra di nuovo alla tua prossima visita</p>
              <p>Tutto qua.</p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
